import { Box, Button, Container, Typography } from "@mui/material";

import { caloTheme } from "../theme/calo";
import { PATH_TYPE } from "../types";

interface BodyProps {
  url: string;
  type: PATH_TYPE;
}

export const Body = ({ url, type }: BodyProps) => {
  return (
    <>
      <Container
        sx={{
          marginBottom: 2,
          borderRadius: 2,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1,
        }}
      >
        <Box marginTop={5} padding={5}>
          <div style={{ marginBottom: 10 }}>{getTitle(type)}</div>
          {getSubtitle(type)}
          {/* <Typography variant="h1">{renderTypeImage(type!)}</Typography> */}
        </Box>
        <Button
          fullWidth
          variant="contained"
          size="large"
          sx={{
            margin: 0,
            padding: 2,
            color: caloTheme.palette.white,
            boxShadow: 'none'
          }}
          onClick={() => window.open(url)}
        >
          Continue to the app
        </Button>
      </Container>
    </>
  );
};

const getTitle = (type: PATH_TYPE) => {
  if (type === PATH_TYPE.AUTH) {
    return (
      <Typography variant="h5" color={caloTheme.palette.white} textAlign="center">
        Welcome to Calo 💚
      </Typography>
    );
  } else {
    return (
      <Typography variant="h5" color={caloTheme.palette.white} textAlign="center">
        Get on the Calo App
      </Typography>
    );
  }
};

const getSubtitle = (type: PATH_TYPE) => {
  if (type === PATH_TYPE.AUTH) {
    return (
      <Typography variant="body2" color={caloTheme.palette.white} textAlign="center">
        Thanks for purchasing from Calo Cafe, login to see the points you collected
      </Typography>
    );
  } else {
    return (
      <Typography variant="body2" color={caloTheme.palette.white} textAlign="center">
        To continue, you will need to sign up or login into the app
      </Typography>
    );
  }
};
