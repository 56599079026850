import { useQuery } from "@tanstack/react-query";

import client from "../core/client";
import { PATH_TYPE } from "../types";

interface OriginalUrlInfo {
  url: string;
  type: PATH_TYPE;
}

const getOriginalUrlInfo = async (url: string) => {
  try {
    const { data } = await client.get<OriginalUrlInfo>(`/original-url`, {
      params: { url: url },
    });
    return data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export const useOriginalUrlInfo = (url: string) => {
  const { data, isLoading, isFetching, isError } = useQuery<OriginalUrlInfo>({
    queryKey: ["url", url],
    queryFn: () => getOriginalUrlInfo(url),
    retry: 2,
    enabled: true,
    staleTime: 1000 * 60 * 30, // 30 minutes if isQueryAutoFetch is true
  });

  return {
    data,
    isLoading,
    isFetching,
    isError,
  };
};
