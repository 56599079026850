import { Box, Link, Typography } from "@mui/material";
import { caloTheme } from "../theme/calo";

export const Error = () => {
  return (
    <Box sx={{ zIndex: 1 }}>
      <Typography variant="h6" color={caloTheme.palette.white} textAlign="center">
        Oops! Unable to find your link.
      </Typography>
    </Box>
  );
};
