import axios, { AxiosInstance } from 'axios';
import { stringify } from 'qs';

let client: AxiosInstance | null = null;

const createClient = () => {
  if (!client) {
    client = axios.create(
        {
          headers: {
            'Content-Type': 'application/json'
          },
          paramsSerializer: (params) => stringify(params)
        }
    );
    client.defaults.baseURL = process.env.REACT_APP_API_URL;
  }

  return client;
}

export default createClient();