import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { caloTheme, caloThemeFont } from "../theme/calo";

export const Header = ({ url }: { url: string | undefined }) => {
  return (
    <Box sx={{ paddingTop: 5 }}>
      <AppBar position="static" sx={{ boxShadow: "none" }} color="transparent">
        <Toolbar>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <Button sx={{ opacity: 0 }}>
              <img src="support.svg" style={{ marginRight: 2 }} />
              <Typography
                sx={{
                  color: caloTheme.palette.white,
                  font: caloThemeFont.typography.fontFamily
                }}
              >
                Help
              </Typography>
            </Button>
          </Box>
          <Box sx={{ justifyContent: 'center', alignItems: 'center', alignContent: 'center' }}>
            <img
              alt="logo"
              src="logo.png"
              style={{
                maxWidth: "100px",
                height: "auto",
                margin: "auto",
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1 }} />
          <Box>
            <Button>
              <img src="support.svg" style={{ marginRight: 2 }} />
              <Typography
                sx={{
                  color: caloTheme.palette.white,
                  font: caloThemeFont.typography.fontFamily,
                }}
              >
                Help
              </Typography>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Toolbar />
    </Box>
  );
};
