import {
  Box,
  CircularProgress,
  CssBaseline,
  ThemeProvider,
} from "@mui/material";
import { useEffect, useState } from "react";
import { OsTypes, isAndroid, osName } from "react-device-detect";
import queryString from "query-string";

import { useOriginalUrlInfo } from "../actions";
import { caloTheme } from "../theme/calo";
import { Body } from "./body";
import { Error } from "./error";
import { Header } from "./header";
import { PATH_TYPE } from "../types";

const Home = () => {
  const paths = window.location.pathname.split("/");
  const mainPath = paths.length > 0 ? paths[1] : "";
  const [path, setPath] = useState<string | undefined>(undefined);
  const [type, setType] = useState<PATH_TYPE | undefined>(undefined);

  const queryParams = queryString.parseUrl(window.location.href);

  const { data, isFetching, isError } = useOriginalUrlInfo(mainPath);

  useEffect(() => {
    if (data?.url && data.type) {
      let finalPath = data.url;
      if (queryParams) {
        finalPath = `${finalPath}&${new URLSearchParams(
          queryParams.query as Record<string, string>
        ).toString()}`;
      }
      setPath(finalPath);
      setType(data.type);
    }
  }, [data]);

  useEffect(() => {
    if (path && (isAndroid || osName === OsTypes.Android)) {
      window.open(path);
    }
  }, [path]);

  return (
    <>
      <ThemeProvider theme={caloTheme}>
        <CssBaseline />
        <div className={isFetching ? '' : `overlay${type === PATH_TYPE.AUTH ? "-auth" : ""}`}>
          <Header url={path} />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            {isFetching && <CircularProgress size={60} />}
            {isError && <Error />}
            {!isFetching && !isError && path && type && (
              <Body type={type} url={path} />
            )}
          </Box>
        </div>
      </ThemeProvider>
    </>
  );
};

export default Home;
